<template>
  <div>
    <div
      class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
      v-if="loading"
    >
      <t-loader extraClass="border-primary-900 w-12 h-12" />
    </div>

    <div v-if="!loading" class="pt-2">
      <div
        class="w-full grid grid-cols-1 xs:grid-cols-2 md:grid-cols-4 gap-2 gap-y-4 mb-4"
      >
        <div style="height: 130px" class="mx-1">
          <stat-card
            :loading="loading"
            :statistic="{
              title: 'Títulos',
              quantity: counters.titles.total,
              icon: 'shield-alt',
              change: counters.titles.actives,
              changeVar: 'títulos activos'
            }"
            bgColor="bg-red-1100"
          />
        </div>

        <div style="height: 130px" class="mx-1">
          <stat-card
            :loading="loading"
            :statistic="{
              title: 'Jugadores',
              quantity: counters.players.total,
              icon: 'users',
              change: counters.players.actives,
              changeVar: 'jugadores activos'
            }"
            bgColor="bg-green-1100"
          />
        </div>
        <div style="height: 130px" class="mx-1">
          <stat-card
            :loading="loading"
            :statistic="{
              title: 'Entrenadores',
              quantity: counters.coaches.total,
              icon: 'chalkboard-teacher',
              change: counters.coaches.actives,
              changeVar: 'entrenadores activos'
            }"
            bgColor="bg-orange-1100"
          />
        </div>

        <div style="height: 130px" class="mx-1">
          <stat-card
            :loading="loading"
            :statistic="{
              title: 'Categorías',
              quantity: counters.categories.total,
              icon: 'chess',
              change: counters.categories.actives,
              changeVar: 'categorías activas'
            }"
            bgColor="bg-yellow-1100"
          />
        </div>
      </div>

      <div class="flex flex-col md:flex-row align-center justify-between my-4">
        <div class="flex flex-col md:flex-row items-center">
          <div class="my-auto mx-2 w-full md:w-2/8">
            <searchbar
              placeholder="Buscar"
              v-model="query"
              @changeQuery="changeQuery"
            ></searchbar>
          </div>
          <t-input-group class="mx-2 w-full md:w-2/8">
            <t-select
              v-model="parameters.active"
              placeholder="Estatus"
              :options="actives"
              textAttribute="name"
              valueAttribute="id"
            ></t-select>
          </t-input-group>
          <t-input-group class="mx-2 w-full md:w-2/8">
            <t-select
              v-model="parameters.verified"
              placeholder="Verificación"
              :options="verifieds"
              textAttribute="name"
              valueAttribute="id"
            ></t-select>
          </t-input-group>
          <t-input-group class="mx-2 w-full md:w-2/8">
            <t-select
              v-model="parameters.position"
              placeholder="Posición"
              :options="positions"
              textAttribute="name"
              valueAttribute="id"
            ></t-select>
          </t-input-group>
        </div>

        <t-button
          variant="warning"
          class="my-auto mr-2 h-fit py-2 lg:text-sm xl:text-md"
          @click="$router.push('/academias/'+$route.params.id+'/categorias/'+$route.params.id2+'/jugadores/crear')"
        >
          Crear jugador
        </t-button>
      </div>

      <div class="w-full">
        <basic-table
          ref="list-table"
          module="player"
          :headers="headers"
          :url="'academias/'+$route.params.id+'/categorias/'+$route.params.id2+'/jugadores'"
          :data="players"
          :route="'/player'"
          :tableName="'JUGADORES'"
          :parameters="parameters"
          @deactivate="deactivate"
          @activate="activate"
          @erase="erase"
        />
      </div>
    </div>
  </div>
</template>

<script>
const notify = () => import("@/utils/notify.js")
import StatCard from "@/components/Statistics/Card.vue"
import Searchbar from "@/components/Searchbar"
export default {
  components: {
    StatCard,
    Searchbar
  },
  data() {
    return {
      loading: true,
      submitLoader: false,
      headers: [
        "",
        "Nombre",
        "Nro de camisa",
        "Sexo",
        "Posición",
        "Pie favorito",
        "Fecha de nacimiento",
        "Acciones"
      ],
      counters: {
        players: {},
        coaches: {},
        categories: {},
        titles: {}
      },
      query: "",
      parameters: {
        active: 1,
        verified: 1,
        order_key: 'jersey_number',
        order_value: 'asc',
        id_category: null,
        id_subcategory: null,
        id_academy: this.$route.params.id,
        gender: null,
        position: null
      }
    }
  },
  computed: {
    user() {
      return this.$store.getters["auth/getUser"]
    },
    categories() {
      return this.$store.getters["global/getItems"](
        "category",
        "unpaginated_elements"
      )
    },
    academies() {
      return this.$store.getters["global/getItems"](
        "academy",
        "unpaginated_elements"
      )
    },
    actives() {
      return [
        {
          id: 1,
          name: "Activos"
        },
        {
          id: '0',
          name: "Inactivos"
        },
      ]
    },
    verifieds() {
      return [
        {
          id: 1,
          name: "Verificados"
        },
        {
          id: '0',
          name: "Pendientes"
        },
        {
          id: 2,
          name: "Rechazados"
        }
      ]
    },
    positions() {
      return Object.entries(this.$store.state.player.positions).map((el) => ({
        id: el[0],
        name: el[1]
      }))
    },
    players() {
      return this.$store.getters["global/getItems"]("player", "elements").map(
        (el) => ({
          id: el.id,
          image: el.url_profile_picture,
          name: el.first_name + " " + el.last_name,
          jersey_number: el.jersey_number == 0 ? 'Ninguna' : el.jersey_number,
          gender: el.gender === 1 ? "Femenino" : "Masculino",
          position: this.$store.state.player.positions[el.position],
          skillful_foot: el.skillful_foot,
          date: el.birthdate,
          active: el.active,
          verified: el.verified
        })
      )
    }
  },
  methods: {
    changeQuery(value) {
      if (this.$refs["list-table"]) this.$refs["list-table"].changeQuery(value)
    },
    getAcademyCounters() {
      this.$store
        .dispatch("global/getItems", {
          route: "user/academy/"+this.$route.params.id+"/counters",
          noPaginate: true
        })
        .then((response) => {
          this.counters = response.data.counters
          this.loading = false
        })
        .catch((error) => {
          this.loading = false
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify)
          })
        })
    },
    getItem() {
      this.$store
        .dispatch("global/getItem", {
          route: 'academy_category',
          module: 'academyCategory',
          id: this.$route.params.id2
        })
        .then((response) => {
          this.parameters.id_category = response.data.id_category
          this.parameters.id_subcategory = response.data.id_subcategory
        })
        .catch((error) => {
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify)
          })
        })
    },
    deactivate() {
      this.counters.players.actives--
    },
    activate() {
      this.counters.players.actives++
    },
    erase() {
      this.counters.players.total--
      this.counters.players.actives--
    }
  },
  created() {
    this.getAcademyCounters()
    this.getItem()
  }
}
</script>
